/*
bootstrap.min.css and bootstrap-icons.min.css are stored locally in public/static/css for offline development
for live, need to change to the jsdeliver urls - uncomment them in index.html
*/


body {
  background-color:#f1f1f1;
}

.ma-cont {

  .ma-block {
    margin-bottom:15px;

    .ma-block-header {
      background-color:rgb(12, 166, 120);
      border-top-left-radius:10px;
      border-top-right-radius:10px;
      padding:5px 10px;
      display:flex;
      font-size:21px;

      span {
        font-weight:600;
        margin-right:auto;
        color:#fff;
      }
      a {
        font-size:24px;
        /*color:rgb(20, 110, 190);*/
        color:#fff;
        margin-left:3px;
      }
    }
    .ma-block-body {
      padding:0 10px 10px 10px;
      background-color:#fff;
      border-bottom-left-radius:10px;
      border-bottom-right-radius:10px;
      box-shadow:rgb(12, 166, 120) 0px 6px 0px 0px;
      font-size:20px;
    }

    .ma-answer {
      background-color:#a5e5a2;
      text-align:center;
    }

    .ma-answer-box-cont {
      display:flex;
    }
    .ma-answer-box {
      border:3px solid #275ea5;
      width:105px;
      height:40px;
      margin-left:auto;
    }
  }

  /* custom block overrides */
  .ma-block-FractionBlock .ma-answer {
    margin-top:10px;
  }
  /* end custom block overrides */

  .fraction-box-cont {
    .box-row {
        display:flex;
      }
    .box {
      width:50px;
      height:50px;
    }

    /*
    .box-image > img {
       width:100px;
       height:100px;
    }
    */
  }

  /* block type styles */
  .romannumerals-cont {
    span {
      text-align:center;
      display:block;
      font-weight:600;
      font-size:34px;
    }
  }

  .multiply-cont .block-body {
    text-align:center;
    font-size:34px;
  }

  .ma-block-CubeVolumeBlock {

    .ma-tile-row {
      display:flex;
      margin-top: -1px !important;
      margin-left: -1px !important;
      
    }
    .ma-tile-row > div {
      margin-top: 2px !important;
      margin-left: 1px !important;
      width:30px;
      height:30px;
    }

    .ma-tile-row > div.ma-tile-on {
      outline:1px solid black;
    }

    .ma-net-inner-cont {
      display:flex;
      padding-top:7px;
    }
    .ma-net-info-cont {
      margin-right:15px;
    }
    .ma-net-arrow {
      text-align: center;
      font-size:42px;
      font-weight:300;
    }
    .ma-net-arrow-up {
      margin-top:-17px;
    }

    .ma-net-length {
      min-width:60px;
    }

    .ma-net-info-cont-short-height {
      .ma-net-arrow {
        text-align: center;
        font-size:28px;
        font-weight:300;
      }
      .ma-net-arrow-up {
        margin-top:-11px;
      }
    }
  }
  .ma-block-TimetableBlock {
    .block-body {
      padding-top:10px;
    }
    table {
      border-collapse:collapse;
      width:100%;
      text-align:center;
    }
    table td {
      font-size:18px;
      border:1px solid black;
    }
    table tr td:first-child {
      text-align:left;
      background-color:#ecebeb;
    }
    .ma-tt-body-inner {
      display:flex;
    }
    .ma-tt-table-cont {
      flex:1;
    }
    .ma-tt-q-cont {
      width:30%;
      padding-right:10px;
    }

  }

  @media (max-width: 992px) {
    .ma-block-TimetableBlock {
      .ma-tt-q-cont {
        width:100%;
        margin-bottom:10px;
      }
      .ma-tt-body-inner {
        display:block;
      }
    }
  }

  /* end block type styles */

  .ma-modal-cont {
    ul {
      margin:0;
      padding:0;
      list-style-type:none;
      display:flex;
      flex-wrap:wrap;
    }
    ul li {
      margin-right:5px;
      margin-bottom:5px;

      button:hover {
        border:1px solid rgb(96, 147, 255);
        color:rgb(96, 147, 255);
        background:#fff;
      }
    }
    button.toggle-selected {
      border:1px solid rgb(96, 147, 255);
      color:rgb(96, 147, 255);
    }
  }

  .frac {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.001em;
    text-align: center;
  }
  .frac > span {
      display: block;
      padding: 0.1em;
  }
  .frac span.denom {
      border-top: thin solid black;
  }
  .frac span.symbol {
      display: none;
  } 

  /* toggle switch https://codepen.io/alvarotrigo/pen/abVPyaJ */
  .ma-toggle-cont {
    display:flex;

    > span {
      margin-right:5px;
    }
  }

  .ma-toolbar {
    display:flex;

    button {
      margin-right:2px;
      font-size:14px;
      padding:5px;
    }

    .btn-icon {
      border:0;
      font-size:26px;
      padding:0;
      margin-top:-6px;
      min-width:32px;
      height:51px;
      background-color:transparent;
      color:#000;
    }

    .btn-icon .bi-plus {
      font-size:40px;
      width:32px;
      display:inline-block;
      margin-top:-4px;
    }
  }

  @media (max-width: 450px) {
    .ma-togglebar {
      display:flex;
      flex-direction:column;
      /*flex-grow:1;*/
      margin-left:auto;
      margin-bottom:5px;
    }

    .ma-toggle-cont > span {
      min-width:72px;
      text-align:right;
    }
  }
  .ma-togglebar {
    display:flex;
  }

  .ma-toggle-cont {
    margin-left:8px;
    margin-top:6px;
  }
  .ma-toggle {

    display:flex;

    input[type=checkbox]{
      height: 0;
      width: 0;
      visibility: hidden;
    }
    
    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 50px;
      height: 25px;
      background: grey;
      display: block;
      border-radius: 25px;
      position: relative;
    }
    
    label:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 2px;
      width: 23px;
      height: 23px;
      background: #fff;
      border-radius: 23px;
      transition: 0.3s;
    }
    
    input:checked + label {
      background: #bada55;
    }
    
    input:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
    
    label:active:after {
      width: 33px;
    }
  }


}

.ma-cont.ma-minimalist {
  .ma-block-header {
    background-color:#fff;
    padding-top:0;
    padding-bottom:0;

    a {
     display:none;
    }

    span {
      color:rgb(12, 166, 120);
    }
  }

  .ma-block {
    border:2px solid rgb(12, 166, 120);
    border-radius:10px;

    .ma-block-body {
      box-shadow:none;
    }
  }
}